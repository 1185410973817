import React, { useEffect, useState } from 'react';
import GenericTable from '../../../../components/GenericTable';
import { useAgentAuth } from '../../../../context/AgentContext';
import AgentDashboardSidebar from '../../../Sidebar';
import moment from 'moment';

// chakra
import {
  Flex,
  Heading,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';

const Invited = () => {
  const { invitedUsers, searchResults, updateAllApplicationsData } =
    useAgentAuth();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    updateAllApplicationsData(() => {
      setLoading(false);
    });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 150,
      },
      {
        Header: 'Invite Sent',
        accessor: 'createdAt',
        minWidth: 150,

        Cell: ({ value }) => moment(value).fromNow('DD/MM/YYYY'),
        sortType: (a, b) => {
          const dateA = a.values.createdAt
            ? new Date(a.values.createdAt)
            : undefined;
          const dateB = b.values.createdAt
            ? new Date(b.values.createdAt)
            : undefined;
          return dateA && dateB ? dateA - dateB : 0;
        },
      },
      {
        Header: 'Status',
        accessor: 'applicationStatus',
        minWidth: 150,
        // disableSortBy: true,
      },

      {
        Header: 'Times Reminded',
        accessor: 'numReminders',
        minWidth: 150,
        // disableSortBy: true,
      },

      {
        Header: 'Actions',
        accessor: 'action',
        minWidth: 150,
        disableSortBy: true,
        isAction: true,
      },

      {
        Header: 'Notes',
        accessor: 'interaction',
        minWidth: 150,
        disableSortBy: true,
        isInteraction: true,
      },
    ],
    []
  );

  return (
    <AgentDashboardSidebar>
      <Heading size="lg">Invited ({invitedUsers?.length})</Heading>
      <Text fontSize={'lg'}>
        Thanks for email inviting these clients to Utopia. Please help them get
        started with their free application (takes {'<5'} mins)
      </Text>

      <Tabs variant="soft-rounded" my="6">
        <TabList
          overflowX="auto"
          whiteSpace="nowrap"
          css={{
            scrollbarWidth: 'none',
            '::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <Tab whiteSpace="nowrap">Invited</Tab>
          <Tab whiteSpace="nowrap">Application Started</Tab>
          <Tab whiteSpace="nowrap">Prequalified</Tab>
          <Tab whiteSpace="nowrap">More Info Required</Tab>
          <Tab whiteSpace="nowrap">In-Review</Tab>
          <Tab whiteSpace="nowrap">Conditionally Approved</Tab>
          <Tab whiteSpace="nowrap">Fully Approved</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <p>Invited</p>
          </TabPanel>
          <TabPanel>
            <p>Application Started</p>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <GenericTable
        columns={columns}
        data={searchResults ? searchResults : invitedUsers}
        loading={loading}
      />
    </AgentDashboardSidebar>
  );
};

export default Invited;

import React, { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import AddCoapplicantCard from '../../Coapplicant/AddCoapplicantCard';
import AddAgentCard from '../../Agents/AddAgent/AddAgentCard';
import FaqCard from '../../FaqCard';

// context
import { useHomeBuyerAuth } from '../../../context/HomebuyerContext';

// chakra
import { ArrowForwardIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Flex,
  Heading,
  Link,
  Spacer,
  Stack,
  Text,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { useAuth } from '../../../context/AuthContext';
import { APPLICATION_STATUS } from '../../../utils/constants';

const HomebuyerApplicationSubmitted = () => {
  const navigate = useNavigate();
  const { applicationData, coApplicationData } = useHomeBuyerAuth();

  const { authUser } = useAuth();

  const [title, subtitle, bottomText, budget] = useMemo(() => {
    let atitle = '',
      aSubtitle = '',
      bottomText = '',
      budget = '';

    let isGeneric = false;

    switch (applicationData?.status) {
      case APPLICATION_STATUS.APPROVED:
      case APPLICATION_STATUS.CONDITIONALLY_APROVED:
      case APPLICATION_STATUS.OFFER_ACCEPTED:
      case APPLICATION_STATUS.OFFER_EXTENDED:
      case APPLICATION_STATUS.OFFER_CLOSED:
      case APPLICATION_STATUS.INSPECTION_COMPLETE:
      case APPLICATION_STATUS.PRECLOSING_COMPLETE:
        atitle = 'Congratulations! You are all set to find your Utopia.';
        aSubtitle = 'Your approved home buying budget is:';

        budget = parseInt(authUser.data?.qualifiedAmount)?.toLocaleString();
        bottomText =
          'This budget is based on your self disclosed financial and operational details. If any material differences are discovered now or later then that may impact your home buying budget.';

        break;
      case APPLICATION_STATUS.REJECTED:
      case APPLICATION_STATUS.PAUSED:
      case APPLICATION_STATUS.INACTIVE:
        atitle = 'Important Update!';

        aSubtitle =
          'Please check your registered email inbox as we have sent critical piece of information to you.';
        // isGeneric = true;
        break;

      case APPLICATION_STATUS.MORE_INFO_REQUIRED:
        atitle =
          'Hello from Utopia! We need some more information from you to get you a suitable home buying budget.';
        aSubtitle =
          'Please check your registered email inbox and share the requested documents by email or by uploading them on this portal.';
        break;

      default:
        if (applicationData?.status) {
          atitle =
            'Hello from Utopia! We are still working on getting you a suitable home buying budget.';
        } else {
          atitle =
            'Thank you for applying! We will get back with you regarding the next steps.';
        }

        isGeneric = true;
    }

    if (isGeneric) {
      aSubtitle = 'Your preliminary home buying budget is:';
      bottomText =
        'Your budget could change once we review your documents or once your co-buyer completes the application.';
      budget =
        applicationData?.stepFive[0].answer === 'yes'
          ? Math.min(
              4.358 *
                (parseInt(applicationData.stepSix[0]?.answer) +
                  parseInt(applicationData.stepSix[4]?.answer)) *
                12,
              998990
            )
          : Math.min(
              4.358 * parseInt(applicationData?.stepSix[0]?.answer) * 12,
              998990
            );

      budget = parseInt(isNaN(budget) ? 0 : budget)?.toLocaleString();
    }

    return [atitle, aSubtitle, bottomText, budget];
  }, [applicationData, authUser]);

  return (
    <Fragment>
      <Container maxW="container.sm">
        <Stack spacing="5">
          <Card align="center" bg="white" size="md" mt="5">
            {/* <CardHeader align="center"></CardHeader> */}
            <CardBody align="center">
              <Heading as="h4" size="md" mb={4}>
                {title}
              </Heading>
              {applicationData && applicationData.stepEight[0] && (
                <Stack>
                  <Text fontSize="md" fontWeight="600" color="gray">
                    {subtitle}
                  </Text>
                  {Boolean(budget) && (
                    <Heading as="h2" size="xl">
                      ${budget}
                    </Heading>
                  )}
                  {Boolean(bottomText) && (
                    <Text fontSize="sm" fontWeight="500" color="gray.400">
                      {bottomText}
                    </Text>
                  )}
                </Stack>
              )}
            </CardBody>
          </Card>

          {coApplicationData?.data?.applicationCompleted === true ? (
            <Fragment>
              <Card align="center" bg="white" size="md" mt="5">
                <CardHeader align="center">
                  <Heading as="h4" size="md">
                    {coApplicationData?.data?.name?.firstName}{' '}
                    {coApplicationData?.data?.name?.lastName}'s Co-Buyer
                    Application is Complete
                  </Heading>
                </CardHeader>
              </Card>
            </Fragment>
          ) : (
            <Fragment>
              {applicationData &&
                applicationData.stepFive[0].answer === 'yes' && (
                  <Fragment>
                    <Card align="center" bg="white" size="md" mt="5">
                      <CardHeader align="center">
                        <Heading as="h4" size="md">
                          Prefill your Co-Buyers's application
                        </Heading>
                      </CardHeader>
                      <CardBody align="center">
                        {coApplicationData && (
                          <Button
                            colorScheme="blue"
                            rightIcon={<ArrowForwardIcon />}
                            onClick={() =>
                              window.open(
                                `/coapplicant/onboarding/${coApplicationData.id}?homebuyer=true`,
                                '_blank'
                              )
                            }
                          >
                            Prefill Application
                          </Button>
                        )}
                      </CardBody>
                    </Card>
                  </Fragment>
                )}
            </Fragment>
          )}

          {applicationData?.addCoBuyerAfterSubmission === true &&
            applicationData?.applicationCompleted === true &&
            applicationData?.stepFive[0].answer === 'no' && (
              <AddCoapplicantCard />
            )}

          <Card align="center" bg="white" size="md">
            <CardBody align="center">
              <Heading as="h4" size="md" mb="4">
                Choose your dream home from our curated listings
              </Heading>
              <Button
                colorScheme="blue"
                rightIcon={<SearchIcon />}
                onClick={() => window.open('/search-homes', '_blank')}
              >
                Search Homes
              </Button>
            </CardBody>
          </Card>

          <Card align="center" bg="white" size="md">
            <CardBody align="center">
              <Heading as="h4" size="md" mb="4">
                Have a Document you need to upload?
              </Heading>
              <Button
                colorScheme="blue"
                rightIcon={<ArrowForwardIcon />}
                onClick={() => navigate(`/documents`)}
              >
                Add a Document
              </Button>
            </CardBody>
          </Card>

          <FaqCard />

          <Card align="center" bg="white" size="md">
            <CardBody align="center">
              <Heading as="h4" size="md" mb="4">
                Still have a question? Just reach out.
              </Heading>
              <Stack spacing="3">
                <Flex>
                  <Text fontSize="md" fontWeight="600" color="gray" mr="3">
                    Email:
                  </Text>
                  <Text fontSize="md" fontWeight="600" color="black">
                    <Link
                      color="blue.400"
                      href="mailto:support@utopia.cx?subject=I have a question"
                    >
                      support@utopia.cx
                    </Link>
                  </Text>
                </Flex>
              </Stack>
            </CardBody>
          </Card>
        </Stack>
      </Container>
    </Fragment>
  );
};

export default HomebuyerApplicationSubmitted;
